import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import TabPanelStyles from './TabPanelStyles';

const useStyles = makeStyles(TabPanelStyles);

const TabPanel = React.memo(({ tabs }) => {
  const classes = useStyles();
  const { hash, pathname } = useLocation();

  function selectedTab() {
    const currentHash = hash?.slice(1).substring(0, 30);
    const index = tabs.findIndex((element) => element.hash === currentHash);
    return index >= 0 ? index : 0;
  }

  function onTabChange(event, value) {
    navigate(`${pathname}#${tabs[value].hash}`);
  }

  return (
    <>
      <Paper className={classes.Paper}>
        <Tabs indicatorColor="primary" textColor="primary" value={selectedTab()} onChange={onTabChange} centered>
          {tabs.map(({ name }) => (
            <Tab key={name} label={name} className={classes.tabLabel} />
          ))}
        </Tabs>
      </Paper>
      <div>{tabs[selectedTab()].container}</div>
    </>
  );
});

TabPanel.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, hash: PropTypes.string, container: PropTypes.element }),
  ).isRequired,
};

export default TabPanel;
