import { blue } from '@material-ui/core/colors';

export default (theme) => ({
  Paper: {
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    minHeight: 48,
    boxShadow: '0 0 13px 0 rgba(82,63,105,.1)',
    borderRadius: 'unset',
  },
  tabLabel: {
    minWidth: theme.spacing(8),
    '&:hover': {
      transition: 'background-color 0.5s ease',
      backgroundColor: blue[500],
      color: '#000',
    },
  },
});
