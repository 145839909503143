import React from 'react';
import { Helmet } from 'react-helmet';

import TabPanel from '#components/page-fragments/TabPanel';
import SourceStatusCard from '#services/orbital-elements/source-retrieval/SourceStatusCard';

import tabs from './catalogs.constants';

const PageTitle = 'Catalogs';

const CatalogsPage = () => {
  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <SourceStatusCard />
      <TabPanel tabs={tabs} />
    </>
  );
};

export default CatalogsPage;
